
































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { User, Environment, EnvironmentUser, View, Link } from '@/models'
import openLink from '@/utils/route/openLink'
import StatusBar from '@/components/mobileApp/StatusBar.vue'
import UserMenu from '@/components/UserMenu.vue'
import NotificationsButton from '@/components/NotificationsButton.vue'
import TellusButton from '@/components/TellusButton.vue'
import gql from 'graphql-tag'
import _snakeCase from 'lodash/snakeCase'
import getEnv from '@/plugins/getEnv'

@Component({
  components: {
    StatusBar,
    UserMenu,
    NotificationsButton,
    TellusButton
  },
  filters: {
    fixLegacyIcons(input: string) {
      if (input && input.indexOf('Md') === 0) {
        return _snakeCase(input.slice(2))
      }
      return input
    },
  },
  apollo: {
    userMenu: {
      query: gql`
        query ($environmentId: ID) {
          userMenu(environmentId: $environmentId)
        }
      `,
      variables() {
        return {
          environmentId: this.environment._id,
        }
      },
    },
  },
})
export default class EnvironmentProfileNavbar extends Vue {
  @Prop({ type: Object, required: true }) environment!: Environment
  @Prop({ type: Object, required: true }) environmentUser!: EnvironmentUser
  @Prop({ type: String }) title?: string
  @Prop({ type: String }) path?: string
  @Prop({ type: Boolean, default: false }) sidebar!: boolean
  @Prop({ type: Boolean, default: false }) sidebarOpen!: boolean
  @Prop({ type: [Number, String] }) notificationCount?: number | string
  @Prop({ type: [Number, String] }) unreadMessageCount?: number | string

  userMenu: Link[] = []
  desktopApp = getEnv('VUE_APP_IS_DESKTOP')

  mounted() {
    this.updateTitle()
  }

  async logout() {
    await this.$store.dispatch('auth/logout')
    await this.$router.push('/login')
    return
  }

  toggleDarkMode() {
    if (!this.environment.allowDarkMode) return
    this.$store.commit('app/toggleDarkMode')
  }

  toggleEditLinks() {
    if (!this.isAdmin) return
    this.$store.commit('app/toggleEditLinks')
  }

  get envProfile() {
    return this.environmentUser.profile
  }

  get user(): User {
    return this.$store.state.auth.user
  }

  get userName() {
    return (
      this.user.profile?.name ||
      this.envProfile?.name ||
      this.envProfile?.nombre
    )
  }

  get userRoleDisplay(): string {
    if (!this.user) return ''
    if (this.user.roles.includes('superAdmin')) {
      return this.$t(
        'Profile_Navbar.script.userRoleDisplay.superAdmin'
      ).toString()
    }
    if (this.user.roles.includes('admin')) {
      return this.$t('Profile_Navbar.script.userRoleDisplay.admin').toString()
    }
    return this.$t('Profile_Navbar.script.userRoleDisplay.user').toString()
  }

  get isAdmin() {
    return (
      this.user.roles.indexOf('admin') >= 0 ||
      this.user.roles.indexOf('superAdmin') >= 0
    )
  }

  get adminLink() {
    if (window.mobileApp || !this.environment) return ''
    if (getEnv('VUE_APP_ADMIN_DOMAIN')) {
      return `https://${getEnv('VUE_APP_ADMIN_DOMAIN')}/${this.environment._id}`
    }
  }

  get legacyAdminLink() {
    if (window.mobileApp || !this.environment) return ''
    if (getEnv('VUE_APP_ADMIN_DOMAIN_V1')) {
      return `https://${getEnv('VUE_APP_ADMIN_DOMAIN_V1')}/${
        this.environment._id
      }`
    }
  }

  updateTitle() {
    if (this.title) {
      document.title = `${this.title} | ${this.environment.name}`
    } else {
      document.title = this.environment.name
    }
  }
}
